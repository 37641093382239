@import url('https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@500&display=swap');

//PAGE
$page-width: 1344px;
//Break points
$screen-xs: 400px;
$screen-sm: 600px;
$screen-md: 768px - 1px;
$screen-lg: 1024px - 1px;
$screen-xl: 1280px - 1px;

//FONTS
$body-fonts: 'Lato', sans-serif;
$title-fonts: 'Oswald', sans-serif;
$font-size: 2rem;
$font-size-l: 2.6rem;
$font-size-xl: 7rem;
$font-size-xxl: 9rem;
$font-size-ml: 5.6rem;
$font-size-mxl: 6.8rem;

//COLORS
$black: hsl(250, 17%, 19%);
$white: hsl(0, 0%, 100%);
$purple: hsl(243, 100%, 68%);
$purple-dark: hsl(242, 34%, 14%);
$purple-light: hsl(244, 100%, 94%);
// $purple-darker: hsl(245, 37%, 32%);

//SPACING

$spacing-s: $font-size * 0.5;
$spacing: $font-size;
$spacing-l: $font-size * 1.5;
